import React, { useEffect, useReducer, useState } from 'react';
import Card from './Card';
import cardsData from '../data/cardsData';
import FilterButton from './FilterButton';
import useFetch from '../hooks/useFetch';
import Loader from './Loader';
import AlertMessage from './AlertMessage';

const Cards = () => {
  const {data, loading, error} = useFetch("/cards?populate=*&pagination[pageSize]=200")

  // MORE CARDS
  const [moreCards, setMoreCards] = useState(9);
  const moreCardsAmount = 6;

  const handleMoreCards = () => {
    setMoreCards((prevValue) => prevValue + moreCardsAmount)
  }

  // FILTER CARDS
  // const [cards, setCards] = useState(cardsData);
  // const cardButtons = [...new Set(cardsData.map((data) => data.category))];
  
  // const filterCards = (current) => {
  //   const newCard = cardsData.filter((newCard) => {
  //     return newCard.category === current;
  //   });

  //   setCards(newCard);
  // };


  return (
    <>
      {/* <FilterButton 
        filterCards={filterCards}
        setCards={setCards}
        cardButtons={cardButtons}
      /> */}

      {loading ? (
        <Loader />
      ) : (
        <ul className="cards">
          {data.slice(0, moreCards).map((item) => (
            <Card
              key={item.id}
              item={item}
            />
          ))}
        </ul> 
      )}

      {error && 
        <AlertMessage 
          message="что-то пошло не так!" 
        />
      }
      
      {moreCards < data.length && (
        <button 
          className='cards__more' 
          type='button' 
          onClick={handleMoreCards}
        > 
          Ещё
        </button>
      )}
    </>
  );
}

export default Cards;