import React, { useState, useEffect } from 'react';
import img2 from '../images/portfolio/work36.jpg';
import img1 from '../images/portfolio/work63.jpg';
import img3 from '../images/portfolio/work60.jpg';
import img4 from '../images/portfolio/work1.jpg';
import img5 from '../images/portfolio/work39.jpg';
import img6 from '../images/portfolio/work4.jpg';
import img7 from '../images/portfolio/work61.jpg';
import img8 from '../images/portfolio/work28.jpg';

const slides = [
  {
    url: `${img1}`
  },
  {
    url: `${img2}`
  },
  {
    url: `${img3}`
  },
  {
    url: `${img4}`
  },
  {
    url: `${img5}`
  },
  {
    url: `${img6}`
  },
  {
    url: `${img7}`
  },
  {
    url: `${img8}`
  },
];


const Slideshow = () => {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      goToNextSlide();
    }, 3000); 

    return () => clearInterval(timer);
  }, [currentIndex]);


  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(nextIndex);
  };
  
  return (
    <>
      {slides.map((slide, index) => (
          <div
            key={index}
            className={`slideshow ${index === currentIndex ? 'active' : ''}`}
            style={{ 
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${slide.url})` 
            }}
          />
        ))}
    </>
  );
}

export default Slideshow;