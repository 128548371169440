import video1 from '../images/videos/dina_videos1.mp4';
import video1poster from '../images/portfolio/work40.jpg'

import video2 from '../images/videos/video_5.mp4';
import video2poster from '../images/portfolio/work65.jpg'

import video3 from '../images/videos/video_4.mp4';
import video3poster from '../images/portfolio/work63.jpg';

import video4 from '../images/videos/video_7.mp4';
import video4poster from '../images/portfolio/work67.jpg';

import video5 from '../images/videos/video_6.mp4';
import video5poster from '../images/portfolio/work16.jpg';

import video6 from '../images/videos/dina_videos2.mp4';
import video6poster from '../images/portfolio/work36.jpg';

import video7 from '../images/videos/dina_videos3.mp4';
import video7poster from '../images/portfolio/work68.jpg';






const videoData = [
  { 
    id: 1,
    video: `${video1}`,
    poster: `${video1poster}`
  },
  { 
    id: 2,
    video: `${video2}`,
    poster: `${video2poster}`
  },
  { 
    id: 3,
    video: `${video3}`,
    poster: `${video3poster}`
  },
  { 
    id: 4,
    video: `${video4}`,
    poster: `${video4poster}`
  },
  { 
    id: 5,
    video: `${video5}`,
    poster: `${video5poster}`
  },
  { 
    id: 6,
    video: `${video6}`,
    poster: `${video6poster}`
  },
  { 
    id: 7,
    video: `${video7}`,
    poster: `${video7poster}`
  },
]

export default videoData;